<template>
  <div>
    <section
      class="bg-gray-200 rounded-md m-2 relative"
      :style="`background-color: ${getBackgroundColor};`"
    >
      <loading
        :height="25"
        color="#1F2937"
        :active="isLoading"
        loader="bars"
        :class="`outline-none`"
        :is-full-page="false"
      />
      <div class="flex justify-between items-center py-4 px-4">
        <div>
          <span class="font-medium"
            >{{
              $t('components.livemapManagement.details.tabs.detail.tasks.title')
            }}
          </span>
          <router-link
            :to="{
              name: 'ViewTaskDetails',
              params: { id: data.id },
            }"
            :class="`text-blue-600`"
            target="_blank"
          >
            <span class="font-medium">
              {{ `#${$truncate(data.id, -5)}` }}
            </span>
          </router-link>
        </div>
        <div class="flex items-center justify-center">
          <div class="tab-items">
            <div
              v-for="tab in tabs"
              :key="`tab-item-${tab.id}`"
              :class="getTabClass(tab.id)"
              v-text="tab.text"
              @click="onChangeTaskPriority(tab.id)"
            />
          </div>
        </div>
      </div>
      <div class="text-black py-2 px-4">
        <div class="flex justify-between items-center">
          <div
            class="rounded-full px-2 py-1"
            :style="`background-color: ${getTaskTypeBgColor};`"
          >
            <span
              class="text-sm font-medium  lowercase "
              :style="`color: ${getTaskTypeTextColor};`"
              >{{ getTaskTypeText }}</span
            >
          </div>
          <div>
            <TaskAddEditStatusDropdown
              class="bg-white rounded-md"
              v-model="taskStatus"
              :options="taskStatusOptions"
              :haveSmallHeight="true"
              :haveBorder="false"
              :haveFill="false"
              hideError
              @change="onChangeTaskStatus"
            />
          </div>
        </div>
        <div>
          <h2 class="text-xl font-medium mt-2">{{ data.title }}</h2>
        </div>

        <div class="flex text-sm items-center py-2">
          <i class="fa fa-user pr-2" />
          <span class="pr-1"
            >{{
              $t(
                'components.livemapManagement.details.tabs.detail.tasks.assignedTo'
              )
            }}:
          </span>
          <span> {{ data.user.full_name }}</span>
        </div>
        <div class="flex text-sm items-center py-2">
          <i class="fa fa-calendar pr-2" />
          <span class="pr-1"
            >{{
              $t('components.livemapManagement.details.tabs.detail.tasks.due')
            }}:
          </span>
          <span v-if="getTimesLeft(data.due_by) === 'Expired'">
            {{ getDate(data.due_by) }} (<TimeAgo
              :title="data.due_by"
              :datetime="data.due_by"
              :auto-update="60"
            />)
          </span>
          <span v-else>
            {{ getTimesLeft(data.due_by) }}
          </span>
        </div>
      </div>
    </section>
    <slot name="vehiclemode" class="p-2"></slot>
  </div>
</template>
<script>
import { useEndpoints } from '@/composables'
import { httpClient } from '@/services'
import TaskAddEditStatusDropdown from '@/composites/task/AddEditStatusDropdown.vue'
import { taskStatusOptions } from '@/composites/task/helpers.js'
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  name: 'TaskItem',
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    TaskAddEditStatusDropdown,
  },
  data() {
    return {
      tabs: [
        {
          id: 'L',
          text: this.$t(
            'components.livemapManagement.details.tabs.detail.tasks.priority.low'
          ),
        },
        {
          id: 'M',
          text: this.$t(
            'components.livemapManagement.details.tabs.detail.tasks.priority.medium'
          ),
        },
        {
          id: 'H',
          text: this.$t(
            'components.livemapManagement.details.tabs.detail.tasks.priority.high'
          ),
        },
      ],
      taskDetails: {},
      taskStatusOptions: taskStatusOptions,
      activePriorityId: '',
      isLoading: false,
    }
  },
  watch: {
    data: {
      deep: false,
      immediate: true,
      handler(val) {
        this.taskDetails = val
        this.activePriorityId = val.priority
      },
    },
  },
  computed: {
    taskStatus: {
      get() {
        return this.taskDetails?.task_status ?? '--'
      },
      set(val) {
        this.taskDetails.task_status = val
      },
    },
    getBackgroundColor() {
      if (this.taskDetails.task_type === 'REBALANCING') {
        return 'rgba(6,142,255,0.14)'
      }
      if (this.taskDetails.task_type === 'MAINTENANCE') {
        return 'rgba(189,0,255,0.14)'
      }
      if (this.taskDetails.task_type === 'CHARGING') {
        return 'rgba(255,21,21,0.14)'
      }
      if (this.taskDetails.task_type === 'SWAP_BATTERY') {
        return 'rgba(245,114,26,0.14)'
      }
      return `#fffff`
    },
    getTaskTypeBgColor() {
      if (this.taskDetails.task_type === 'REBALANCING') {
        return '#b4dafa'
      }
      if (this.taskDetails.task_type === 'MAINTENANCE') {
        return '#e5bafa'
      }
      if (this.taskDetails.task_type === 'CHARGING') {
        return '#fdc6c6'
      }
      if (this.taskDetails.task_type === 'SWAP_BATTERY') {
        return '#fee3c8 '
      }
      return `#ffffff`
    },
    getTaskTypeText() {
      if (this.taskDetails.task_type === 'CHARGING') {
        return 'recharging'
      }
      return this.taskDetails.task_type.replace('_', ' ')
    },
    getTaskTypeTextColor() {
      if (this.taskDetails.task_type === 'REBALANCING') {
        return '#0683ff'
      }
      if (this.taskDetails.task_type === 'MAINTENANCE') {
        return '#bd00ff'
      }
      if (this.taskDetails.task_type === 'CHARGING') {
        return '#ff1515'
      }
      if (this.taskDetails.task_type === 'SWAP_BATTERY') {
        return '#ff8a15'
      }
      return `#ffffff`
    },
  },
  methods: {
    getDate(clock) {
      return getFormattedDateTime(clock, 'D MMM, YYYY')
    },
    getTabClass(tabId) {
      return {
        'tab-item': true,
        'tab-item--active': this.activePriorityId === tabId,
      }
    },
    onClickTab(tabId) {
      this.activePriorityId = tabId
    },
    async onChangeTaskPriority(tabId) {
      this.isLoading = true
      const url = useEndpoints.task.update(this.data.id)
      const data = new FormData()
      data.append('priority', tabId)
      await httpClient
        .patch(url, data)
        .then(() => {
          // console.log(res.data)
          this.activePriorityId = tabId
          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: `Success`,
            text: `Task priority has been changed.`,
          })
        })
        .catch((err) => {
          console.log(err)
          // this.taskStatus = oldValue
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [${err.response.status}]`,
            text:
              err.response.data.message ??
              'Could not change task priority, please try again.',
          })
        })
        .finally(() => (this.isLoading = false))
    },
    async onChangeTaskStatus(newValue, oldValue) {
      this.isLoading = true
      const url = useEndpoints.task.update(this.data.id)
      const data = new FormData()
      data.append('task_status', newValue)
      await httpClient
        .patch(url, data)
        .then(() => {
          // console.log(res.data)
          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: `Success`,
            text: `Task Status has been changed`,
          })
        })
        .catch((err) => {
          console.log(err)
          this.taskStatus = oldValue
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [${err.response.status}]`,
            text:
              err.response.data.message ??
              'Could not change task status, please try again.',
          })
        })
        .finally(() => (this.isLoading = false))
    },
    getTimesLeft(timeStr) {
      const currentTime = new Date()
      const focusTime = new Date(timeStr)
      focusTime.setHours(23)
      focusTime.setMinutes(59)
      focusTime.setSeconds(59)
      const diffTime = focusTime - currentTime
      // const diffTime = Math.abs(focusTime - currentTime)
      console.log('diffTime', diffTime)

      return this.milisecToDHMSConverter(diffTime)
    },
    //millisecond converted
    milisecToDHMSConverter(ms) {
      const days = Math.floor(ms / (24 * 60 * 60 * 1000))
      const daysms = ms % (24 * 60 * 60 * 1000)
      const hours = Math.floor(daysms / (60 * 60 * 1000))
      const hoursms = ms % (60 * 60 * 1000)
      const minutes = Math.floor(hoursms / (60 * 1000))
      const minutesms = ms % (60 * 1000)
      const seconds = Math.floor(minutesms / 1000)
      if (days > 0) {
        return `${days} d ${hours} h ${minutes} m ${seconds} s left`
      }
      if (hours > 0 && days <= 0) {
        return `${hours} h ${minutes} m ${seconds} s left`
      }
      if (minutes > 0 && hours <= 0 && days <= 0) {
        return `${minutes} m ${seconds} s left`
      }
      if (seconds > 0 && minutes <= 0 && hours <= 0 && days <= 0) {
        return `${seconds} s left`
      }
      if (seconds <= 0 && minutes <= 0 && hours <= 0 && days <= 0) {
        return 'Expired'
      }
    },
    getTaskStatusDisabled() {
      return ['DROPPED', 'CANCELLED'].includes(this.taskDetails?.task_status)
    },
  },
}
</script>
<style lang="scss">
.tab-items {
  @apply flex max-w-sm rounded-full bg-white border border-gray-300 gap-1 transition-all duration-200 ease-in-out;

  .tab-item {
    min-width: 50px;
    @apply flex items-center justify-center text-center text-xs cursor-pointer py-1 px-2 font-medium text-gray-500 rounded-full hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900;

    &--active {
      @apply bg-blue-700 text-white;
      &:hover {
        @apply bg-blue-600 text-white;
      }
    }
  }
}
</style>
